@use '../../styles/mixins.scss';
@use '../../styles/breakpoints.scss';

.row {
  width: 100%;
  display: flex;
  justify-content: space-around;

  @include mixins.media-w(breakpoints.$mobile-m) {
    justify-content: space-between;
  }
}

.footer {
  padding-top: 20px;
  font-size: 16px;
  line-height: 150%;
  hyphens: auto;
  text-align: center;
}

.or {
  display: block;
  padding-bottom: 15px;
  font-weight: 700;
}

.wallet {
  hyphens: auto;
  word-wrap: anywhere;
}
