@use '../../styles/mixins.scss';
@use  '../../styles/breakpoints.scss';
.component {
  @include mixins.fluidMaxSize(190);
  margin: -90px auto 20px;
  filter: drop-shadow(0px 10px 19px rgba(0, 138, 198, 0.67));

  @include mixins.media-w(breakpoints.$tablet) {
    @include mixins.fluidMaxSize(145);
    margin-top: -65px;
    margin-bottom: 15px;
    filter: none;
  }
}
