@use "./colors";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  color: colors.$white;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

input, button {
  border: none;
  outline: none;
  font-family: inherit;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
