@use '../../styles/colors.scss';
@use '../../styles/mixins.scss';
@use  '../../styles/breakpoints.scss';

.title {
  font-size: 22px;
  line-height: 150%;
  font-weight: 600;
  color: colors.$white;
  text-align: center;
  padding-bottom: 15px;

  @include mixins.media-w(breakpoints.$tablet) {
    font-size: 18px;
  }
  @include mixins.media-w(breakpoints.$mobile-m) {
    font-size: 16px;
  }
}
