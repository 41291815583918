@use "../../styles/colors.scss";
@use "../../styles/mixins.scss";
@use "../../styles/breakpoints.scss";

.component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 35%;

  @include mixins.media-w(breakpoints.$mobile-m) {
    width: 40%;
  }
}

.label {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 8px;
}

.input {
  width: 100%;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  padding: 12px 5px;
  color: colors.$white;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid colors.$white;
  border-radius: 10px;
  text-overflow: ellipsis;
}

.input::placeholder{
  color: colors.$white;
}
