@use '../../styles/colors.scss';
@use '../../styles/mixins.scss';

.component {
	@include mixins.fluidMaxSize(140);
	margin: 0 auto 28px;
}

.row {
	@include mixins.flex-spb;
}

.text {
	font-size: 16px;
	line-height: 120%;
	color: colors.$white;
	opacity: 0.5;

	&.active {
		opacity: 1;
	}
}

.switcher {
	margin-top: 6px;
	border: 1px solid #88D1EF;
	color: #52c9e7cc;

	& > div {
		box-shadow: inset 0px 0px 6px #00567E;
	}
}
