@use './styles/mixins.scss';
@use './styles/breakpoints.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @include mixins.flex-center;
  flex-flow: column;
  padding: 120px 20px 70px;

  @include mixins.media-w(breakpoints.$tablet) {
    padding: 80px 20px 40px;
  }
}

.videoContainer {
  height: 440px;
  margin-bottom: 120px;
  max-width: 850px;
  position: relative;
  width: 100%;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include mixins.cover;
  opacity: 1;

  @include mixins.media-w(breakpoints.$tablet) {
    background-position: 80% 50%;
  }
}

.wrapper {
  position: relative;
  @include mixins.fluidMaxSize(410);
  padding: 0 40px 42px;
  border-radius: 30px;
  background: linear-gradient(180.2deg, rgba(0, 209, 255, 0.35) 12.23%, rgba(0, 71, 137, 0.36) 45.97%, rgba(0, 96, 150, 0.37308) 71.43%, rgba(9, 137, 255, 0.35) 98.47%), radial-gradient(137.39% 50% at 50% 50%, rgba(117, 255, 247, 0.3) 0%, rgba(153, 212, 255, 0.5) 100%);
  backdrop-filter: blur(4px);
  border: 1px solid #82E8FF;

  @include mixins.media-w(breakpoints.$tablet) {
    backdrop-filter: blur(6px);
    padding: 0 22px 22px;
  }

}

.rowInput {
  width: 100%;
  display: flex;
  justify-content: space-around;

  @include mixins.media-w(breakpoints.$mobile-m) {
    justify-content: space-between;
  }
}