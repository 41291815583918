@use "../../styles/colors.scss";
@use "../../styles/mixins.scss";
@use "../../styles/breakpoints.scss";

.button {
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: colors.$white;
  line-height: 120%;
  margin: 36px auto 0;
  padding: 18px 80px;
  background: #017CD7;
  box-shadow: -2px 2px 2px rgba(24, 145, 236, 0.67);
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.4s;

  @include mixins.media-w(breakpoints.$tablet) {
    width: 100%;
    text-align: center;
  }
}

.button.disabled {
  opacity: 0.5;
  cursor: no-drop;
}

@include mixins.media-minw(breakpoints.$laptop) {
  .button:not(.disabled):hover {
    background: #026ec0;
    transform: scale(0.97);
  }
}
